<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                德邦证券联合非凸科技，开展“乘长会客厅”北京站活动
              </p>
              <div class="fst-italic mb-2">2023年11月30日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >德邦证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/62/01-德邦.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，德邦证券联合非凸科技开展了“乘长会客厅”北京站活动，与机构投资者、私募管理人等行业资深人士，就算法赋能交易、策略研发效率等方面展开了深度交流。活动期间，分享人的独特见解，获得了热烈反响。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/62/02-俞斌.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近年来，德邦证券积极借助科技力量，赋能高价值业务场景，持续推动资产管理、精品投行、投资业务、财富管理等业务创新。德邦证券正持续发力构建“基金云选生态圈”，设计了由观察池、潜力池、甄选池构建的基金云选池，从定性、定量、持仓、净值等多角度开展评估，为包括金融机构在内的各类机构资方提供特色的基金管理人服务，成为市场各资金方和投资管理人之间的连接枢纽。除此之外，德邦证券的私募“乘长”计划，旨在持续挖掘与赋能高潜力私募管理人，并满足个人及机构客户财富长期保值增值的配置需求，通过长效私募孵化机制，构建与高净值客群的共赢生态圈。活动上，德邦证券北京营业部总经理俞斌表示，德邦证券将继续秉承自主创新理念，深化财富管理转型，致力于在金融领域创造更多价值，为行业的高质量发展贡献力量。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/62/03-朱为.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随后，非凸科技北京分公司合伙人朱为以“智能算法与平台赋能量化交易”为主题进行了分享。非凸科技具备自研的实力和潜力，可以提供专业的算法工具与服务，满足机构客户对策略高效性与系统稳定性的极致追求。非凸智能交易总线解决方案，打通了算法、交易、执行、风控等多链路需求，赋能机构业务精益增长。与此同时，非凸科技积极拥抱监管新规，全面支持机构客户合规风控工作，为量化交易的全生命周期保驾护航。非凸科技也将持续通过累积算法交易实践和智能交易平台，基于更先进的技术和更懂业务的产品，持续为量化生态圈赋能。
                  </p>
                  <p>
                    在开放议题环节，德邦证券北京营业部机构部赵一帅就“如何提升策略研发效率”主题与非凸科技进行了深度探讨。朱为表示，非凸科技拥有完善的策略开发平台，支持从策略开发、调试、回测、仿真交易、实盘交易、绩效分析与对比、智能路由等策略研发全生命周期功能，能够为机构客户提供更优质、更稳定的绩效。针对回测与仿真的绩效数据与实盘交易是否有较大误差的问题，朱为解释道，非凸科技通过细节上的持续优化，可以实现更高精度的回测。仿真模拟相对回测来说，由于使用了真实行情，具备更随机的延迟和订单竞争等真实场景，能够更精准地模拟出策略效果。除此之外，非凸科技将实盘版本与开发中多版本同时进行模拟盘对比测试，由于测量标准一样，所以相对效果也较为客观。
                  </p>
                  <p>
                    未来，非凸科技将携手德邦证券等行业伙伴，继续深耕前沿技术，把不断突破的技术创新与行业需求相结合，共同打造场景化解决方案，繁荣量化行业生态，推动金融市场持续健康发展。
                  </p>
                  <p>
                    12月7日，非凸科技将联合德邦证券举办“乘长会客厅”深圳站活动，期待与大家见面交流。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News62",
};
</script>

<style></style>
